<template>
  <el-table
    :data="filteredReport20.finalResult"
    border
    class="h-100"
    size="small"
    v-loading="reportsStore.table_loading"
    :row-style="tablerowstyle"
  >
    <el-table-column
      align="center"
      width="180"
      prop="get_truck_brand_model"
      label="Avtomashina"
    />

    <el-table-column
      align="center"
      width="180"
      prop="get_truck_number"
      label="Gos. nomer"
    />

    <el-table-column width="180" label="Yurgan km">
      <!-- <el-table-column align="center" width="180" label="Haqiqiy" />

      <el-table-column align="center" width="180" label="Mijoz uchun" />-->
      <el-table-column
        align="center"
        width="110"
        v-for="(item, index) in 12"
        :key="item + 1"
        :label="months[item - 1]"
      >
        <el-table-column align="center" width="110" label="Haqiqiy">
          <template slot-scope="scope">{{
            scope.row.passed_distance &&
            scope.row.passed_distance[index] &&
            scope.row.passed_distance[index][item] &&
            scope.row.passed_distance[index][item].real
          }}</template>
        </el-table-column>

        <el-table-column align="center" width="110" label="Mijoz uchun">
          <template slot-scope="scope">{{
            scope.row.passed_distance &&
            scope.row.passed_distance[index] &&
            scope.row.passed_distance[index][item] &&
            scope.row.passed_distance[index][item].client
          }}</template>
        </el-table-column>
      </el-table-column>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import monthNames from '@/constants/js/month.names.js'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  computed: {
    ...mapState(['reportsStore']),
    ...mapGetters(['filteredReport20']),
    months() {
      return monthNames || []
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Odometr' }])
    })
  },

  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  }
}
</script>
